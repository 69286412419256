import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import countries from "i18n-iso-countries";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import b from "../staticAssets/logo.png";
import ErrorModal from "../components/ErrorModal";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const countryObj = countries.getNames("en", { select: "official" });

const countryArr = Object.entries(countryObj).map(([key, value]) => {
  return {
    label: value,
    value: key,
  };
});

function Checkout({ location }) {
  const [emessage, setemessage] = useState("");
  const [error, seterror] = useState(false);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: location?.state?.client_secret,
  };

  return (
    <>
    {error && <ErrorModal data={{ message: emessage, seterror }}/>}
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm origin={location.origin} setemessage={setemessage} seterror={seterror}/>
    </Elements>
    </>
  );
}

const CheckoutForm = ({origin, setemessage, seterror}) => {
  
  
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    //strie create customer then send details in payment intent
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: `${origin}/payment`,
        },
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        seterror(true);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="fixed h-screen container mx-auto flex justify-center items-center">
      <div className="rounded-xl shadow-2xl bg-white flex flex-col relative overflow-hidden">
        <div className="w-full bg-gradient-to-r from-blue-100 to-orange-100 relative h-16 flex items-center justify-center shadow">
          <h1 className="text-center text-lg">Payment Details</h1>
          <div className="absolute right-0 p-4">
            <img loading="lazy" className="h-10 w-10" src={b} />
          </div>
        </div>
        <div className="px-8 md:px-20 py-10 bg-white">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col">
              <PaymentElement />
              <button
                className="bg-blue-500 text-white mt-4 px-4 py-1 rounded w-20"
                disabled={!stripe}
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
